import React from 'react';
import { Container } from 'react-bootstrap';
import GiftSection from '../../components/giftSection/GiftSection.js';

export const Gift = (props) => {
  return (
    <Container className="text-center RootGift" id="que-conseguire">
      <div className="RootGift-inside h-100" style={{ zIndex: '89' }}>
        <GiftSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Gift;
