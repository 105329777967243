import React, { Suspense } from 'react';
import { useSpring } from 'react-spring';

import { Container } from 'react-bootstrap';

// PAGES
import Layout from '../../../layout/Layout';
import ClassTitle from './landing/pages/classTitle/ClassTitle';
// import ClassVideo from './landing/pages/classVideo/ClassVideo';
import ButtonClass from './landing/pages/button/Button';
import Footer from '../../../components/footer/Footer';

const ClassVideo = React.lazy(() =>
  import('./landing/pages/classVideo/ClassVideo')
);

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const ThanksPage = (props) => {
  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];
  const [properties, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <Layout>
      <Container
        fluid
        id="inicio"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <div className="textureBack" />
        <ClassTitle />
        <Suspense fallback={<div></div>}>
          <ClassVideo />
        </Suspense>
        {/* <ButtonClass /> */}
        {/* <Footer /> */}
      </Container>
    </Layout>
  );
};

export default ThanksPage;
