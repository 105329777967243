import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ProgramSection = () => {
  return (
    <Row
      className="programSectionRow h-100"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <Col xs={12} lg={6} className="programImgCol d-none d-lg-flex">
        <img
          alt=""
          className="programImg"
          src={require('../../../../assets/img/photos/10---Twerkealo-min-min-min.png')}
        ></img>
      </Col>
      <Col xs={12} lg={6} className="programContent">
        <Row>
          <Col xs={12}>
            <h2 className="h2Title d-none d-lg-inline">
              ¿Qué te espera en esta <br />
              <span className="boldText">Clase GRATUITA de TWERK?</span>
            </h2>
            <h2 className="h2Title d-inline d-lg-none">
              ¿Qué te espera en esta{' '}
              <span className="boldText">Clase GRATUITA de TWERK?</span>
            </h2>
            <p className="pText">
              Lo daremos todo para que con este entrenamiento de 1 hora seas
              capaz de TWERKEAR como se debe. Iremos de menos a más, desde lo
              básico a lo avanzado, no es necesario ningún conocimiento previo,
              pero eso sí, ¡LAS GANAS no pueden faltar!
            </p>
            <img
              alt=""
              className="programImg d-flex d-lg-none"
              src={require('../../../../assets/img/photos/10---Twerkealo-min-min-min.png')}
            ></img>
          </Col>
          <Col xs={12} className="textSquareCol">
            <div
              className="pText textSquare"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h3 className="h3Title">Intro al Twerk:</h3>
              <p className="pText">
                No faltará lo básico, te enseñaremos la postura adecuada y el
                paso básico para empezar a darlo todo en la pista de baile.
              </p>
              <div className="topLine"></div>
              <div className="rightLine"></div>
              <div className="leftLine"></div>
              <div className="bottomLine"></div>
            </div>
          </Col>
          <Col xs={12} className="textSquareCol">
            <div
              className="pText textSquare"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h3 className="h3Title">Shake:</h3>
              <p className="pText">
                ¿Has visto el paso típico de Twerk de cadera hacia delante y
                hacia atrás (probablemente te imaginas que es difícil)? Ese paso
                que hacen las bailarinas de videos o chicos que dan todo su flow
                en las pistas de baile. No te preocupes, también te lo
                enseñaremos. Aprenderás a disociar tu cadera para dominar este
                paso.
              </p>
              <div className="topLine"></div>
              <div className="rightLine"></div>
              <div className="leftLine"></div>
              <div className="bottomLine"></div>
            </div>
          </Col>
          <Col xs={12} className="textSquareCol">
            <div
              className="pText textSquare"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h3 className="h3Title">Wine:</h3>
              <p className="pText">
                ¿Los círculos con la cadera te suenan de algo? Todos lo hemos
                hecho alguna vez, pero ¿Sabes la técnica correcta para hacerlo y
                que salga como la expectativa que tenemos en mente? Te
                enseñaremos cómo, es más fácil de lo que piensas.
              </p>
              <div className="topLine"></div>
              <div className="rightLine"></div>
              <div className="leftLine"></div>
              <div className="bottomLine"></div>
            </div>
          </Col>
          <Col xs={12} className="textSquareCol">
            <div
              className="pText textSquare"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h3 className="h3Title">Jiggle:</h3>
              <p className="pText">
                Con este paso vas a flipar. No sólo moverás glúteos y cadera,
                sino que ejercitarás todo el cuerpo ya lo verás. ¡Un paso
                inolvidable cuando Twerkeas! Adelante y hacia atrás moverás tus
                glúteos al ritmo de la música. Torso estático y piernas
                extendidas, requisitos básicos para Jiggle, pero no te preocupes
                no es difícil.
              </p>
              <div className="topLine"></div>
              <div className="rightLine"></div>
              <div className="leftLine"></div>
              <div className="bottomLine"></div>
            </div>
          </Col>
          <Col xs={12} className="textSquareCol">
            <div
              className="pText textSquare"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h3 className="h3Title">Coreografía paso a paso:</h3>
              <p className="pText">
                no nos olvidamos de la coreo. Paso a paso te guiaremos por una
                coreo completa para que practiques y te demuestres que el Twerk
                es un estilo de baile fácil de aprender. El ritmo se logra con
                constancia y aquí te la enseñamos.
              </p>
              <div className="topLine"></div>
              <div className="rightLine"></div>
              <div className="leftLine"></div>
              <div className="bottomLine"></div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProgramSection;
