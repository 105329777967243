import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import ClassTitleSection from '../../components/classTitleSection/ClassTitleSection';

export const Home = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <Row className="thanksRootHome">
      <div className="thanksRootHome-inside" style={{ zIndex: '99' }}>
        <ClassTitleSection
          show={show}
          onHide={handleClose}
          onShow={handleShow}
        />
      </div>
    </Row>
  );
};

export default Home;
