import React, { useEffect } from 'react';
import { Jumbotron, Button, Row, Col, Container } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';

import { isMobile } from '../../../../../../layout/Layout';

// import hoverEffect from 'hover-effect';

// import Smooth from '../../../../components/skewScroll/SkewScroll';

export const GridHome = (props) => {
  const mobile = isMobile();

  return (
    <Container fluid>
      <Row className="rowHome">
        <Col
          xs={12}
          lg={{ span: 12 }}
          className="homeCol homeColContent"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          {/* <Parallax
            className="custom-class"
            y={[20, -20]}
            tagOuter="figure"
            disabled={mobile}
          > */}
          <Jumbotron className="jumbotronHome jumbotronTitleClass">
            <h1 className="h1Title">
              <span className="h1TitleRelleno">MasterClass de</span> TWERK
            </h1>
            <h3 className="h3Title">
              Descubre cómo Subir tu Autoestima, Ejercitarte y
              <br />
              Conectar con el Ritmo que llevas Dentro a través del TWERK
            </h3>
          </Jumbotron>
          {/* </Parallax> */}
        </Col>
      </Row>
    </Container>
  );
};

export default GridHome;
