import React from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col, CardDeck, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const ParagraphSection = (props) => {
  return (
    <Row className="thanksVipSectionRow h-100">
      <Col
        xs={12}
        lg={12}
        className="thanksVipCol thanksVipColContent"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <CardDeck className="pCardDeck">
          <Card className="thanksCardStyle">
            <Card.Body>
              <Card.Text className="pText">
                TEN EN CUENTA QUE: La clase es de acceso limitado y SOLO tienes
                24 HORAS para verla desde el momento que hagas click sobre el
                botón y accedas a la clase.
              </Card.Text>
            </Card.Body>
            <div className="topLine"></div>
            <div className="rightLine"></div>
            <div className="leftLine"></div>
            <div className="bottomLine"></div>
          </Card>
        </CardDeck>
      </Col>
      <Col
        xs={12}
        lg={6}
        xl={6}
        className="buttonThanksYouCol"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h2 className="h2Title">¿Estás preparadx?</h2>
        <p className="pText thanksButtonTextP">
          Si tienes tantas ganas como nosotras de aprender a expresarte a través
          del baile, sacando todo el ritmo que llevas dentro,
          <br />
          <br />
          Haz click aquí debajo y ve a la clase
        </p>
        <div className="thanksButtonContainer">
          <Link to="/accede-a-la-clase-gratis">
            <Button
              bsPrefix
              className="buttonText thanksButtonText thanksButtongift buttonImage"
            >
              <span className="buttonTitle">ENTRAR A LA CLASE AHORA</span>
            </Button>
          </Link>
        </div>
      </Col>
      <Col xs={12} className="thanksPageImgCol">
        <div className="imgContainer">
          <img
            className="luciaOliviaImg"
            src={require('../../../../../../assets/img/photos/thankspage.png')}
          ></img>
        </div>
      </Col>
      <Col
        xs={12}
        lg={12}
        className="thanksVipCol thanksVipColContent"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <CardDeck className="pCardDeck">
          <Card className="thanksCardStyle">
            <Card.Body>
              <Card.Text className="pText">
                Ya has desbloqueado tu plaza para la MasterClass de Twerk, haz
                click en el botón “Entrar a la clase ahora” para ir a verla.
              </Card.Text>
            </Card.Body>
            <div className="topLine"></div>
            <div className="rightLine"></div>
            <div className="leftLine"></div>
            <div className="bottomLine"></div>
          </Card>
        </CardDeck>
        <CardDeck className="pCardDeck">
          <Card className="thanksCardStyle">
            <Card.Body>
              <Card.Text className="pText">
                Si no puedes verla ahora mismo no te preocupes, recibirás por
                email el link de acceso a la clase, PERO...
              </Card.Text>
            </Card.Body>
            <div className="topLine"></div>
            <div className="rightLine"></div>
            <div className="leftLine"></div>
            <div className="bottomLine"></div>
          </Card>
        </CardDeck>
        <CardDeck className="pCardDeck">
          <Card className="thanksCardStyle">
            <Card.Body>
              <Card.Text className="pText">
                Si tienes cualquier duda, puedes escribirnos a través de
                nuestras redes o haciendo click en este link que te dejamos
                aquí: <br />
                <a href="mailto:soporte.olivia@suprive.com">
                  Haz click aquí si no puedes acceder a la clase
                </a>
              </Card.Text>
            </Card.Body>
            <div className="topLine"></div>
            <div className="rightLine"></div>
            <div className="leftLine"></div>
            <div className="bottomLine"></div>
          </Card>
        </CardDeck>
      </Col>
    </Row>
  );
};

export default ParagraphSection;
