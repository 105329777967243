import React from 'react';
import { Jumbotron, Row, Col, Container } from 'react-bootstrap';
import './GridHome.scss';

export const GridHome = (props) => {
  return (
    <Container fluid>
      <Row className="thanksRowHome">
        <Col
          xs={12}
          lg={{ span: 12 }}
          className="thanksHomeCol thanksHomeColContent"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <Jumbotron className="thanksJumbotronHome">
            <h1 className="h1TitleThanks d-none d-lg-block">
              <span className="">¡ENHORABUENA, </span>
              <span className="h1TitleThanksRelleno">
                Estamos felices de que estés dentro!
              </span>
            </h1>
            <h1 className="h1TitleThanks d-block d-lg-none">
              <span className="">¡ENHORABUENA, </span>
              <span className="h1TitleThanksRelleno">
                Estamos felices de que estés dentro!
              </span>
            </h1>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default GridHome;
