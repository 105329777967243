import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const UniqueSection = () => {
  return (
    <Row
      className="uniqueSectionRow h-100 justify-content-center"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-offset="-400"
    >
      <Col xs={12} lg={6} className="d-block d-lg-none">
        <div
          className="stickyTopNav"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 className="h2Title ">
            ¿Por qué deberías asistir
            <br />a esta Clase <span className="boldText">Gratis de Twerk</span>
            ?
          </h2>
          <img
            alt=""
            className="uniqueImg"
            src={require('../../../../assets/img/photos/3---Twerkealo-min-min.png')}
          ></img>
        </div>
      </Col>
      <Col
        xs={12}
        lg={4}
        className="uniqueContent becauseCol1"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">OLVÍDATE de tu alrededor</h2>
          <p className="pText">
            Porque hay que decir BASTA, olvidarte de lo que piensen los demás y
            empezar a expresarte a través de la danza que te gusta. Quieres
            descubrir la verdadera esencia de este ritmo creativo y muy
            divertido para demostrar a los demás que puedes hacer Arte a través
            del Twerk. Ha llegado el momento de Olvidarte de la vergüenza y
            enfrentar tus temores.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">Haz EJERCICIOS mientras te DIVIERTES</h2>
          <p className="pText">
            Porque el Twerk es un estilo de baile que puede ayudar a perder
            hasta 600 calorías por hora. Si te cansan las típicas rutinas de
            ejercicios estrictas e imposibles de mantener en el tiempo.
            Practicando Twerk, conseguirás tonificar tu cuerpo y perder grasa
            sin desear que la clase se acabe pronto.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">Sube tu AUTOESTIMA y dale RITMO a tu vida</h2>
          <p className="pText">
            Porque sientes vergüenza al bailar y piensas que el baile no es lo
            tuyo repitiendo la típica excusa de “no tengo ritmo”. En este
            entrenamiento aprenderás desde lo básico del twerk hasta lograr una
            coreografía entera. No necesitas experiencia previa o “haber nacido”
            con el don del baile. Sin importar tu nivel, podrás practicar este
            apasionante estilo de baile que te ayudará a subir tu autoestima y a
            darle rienda suelta al ritmo que todos llevamos dentro.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">Mejora POSTURAS</h2>
          <p className="pText">
            Porque el Twerk es uno de los pocos estilos de baile con el que no
            solo te diviertes y ejercitas, también puedes mejorar tu postura y
            progresivamente mejorar tu circulación hacia todas tus
            articulaciones. Al disociar las diferentes zonas de tu cuerpo, te
            sentirás más flexible cada día.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">
            No olvidemos los GLÚTEOS y hacerlos CRECER
          </h2>
          <p className="pText">
            Porque siempre has deseado crecer y tonificar tus glúteos y piernas,
            pero las sentadillas y máquinas de gimnasio no son lo tuyo.
            Twerkeando podrás darle “grandeza” a los glúteos y que nunca más
            pasen desapercibidos. Práctica tras práctica mejorarás, sin darte
            cuenta (ni aburrirte), su forma, piel, músculo y tamaño, vamos todo
            en uno.
          </p>
          <div className="whiteLine"></div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="d-none d-lg-block becauseCol2">
        <div
          className="stickyTopNav"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h2 className="h2Title ">
            ¿Por qué deberías asistir
            <br />a esta Clase <span className="boldText">Gratis de Twerk</span>
            ?
          </h2>
          <img
            alt=""
            className="uniqueImg"
            src={require('../../../../assets/img/photos/3---Twerkealo-min-min.png')}
          ></img>
        </div>
      </Col>
      <Col xs={12} className="uniqueColButton">
        <Button
          href="#vip"
          bsPrefix
          className="buttonText buttonsUnique buttonImage"
        >
          <span className="buttonTitle d-none d-lg-flex">
          Acceder a la MasterClass de Twerk
          </span>
          <span className="buttonTitle d-flex d-lg-none">
          Acceder a la<br />MasterClass de Twerk
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UniqueSection;
