import React from 'react';
import { Container } from 'react-bootstrap';
import ParagraphSection from '../../components/paragraphSection/ParagraphSection';

export const Paragraph = (props) => {
  return (
    <Container className="thanksRootParagraph" id="paragraph">
      <div
        className="thanksRootParagraph-inside h-100"
        style={{ zIndex: '90' }}
      >
        <ParagraphSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Paragraph;
