import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';
import { isMobile } from '../../../../layout/Layout';

const GiftSection = () => {
  const mobile = isMobile();

  return (
    <Row className="giftSectionRow h-100 justify-content-center align-items-center">
      <Col xs={12} lg={6} xl={6} data-aos="fade-up" data-aos-duration="1000">
        <h2 className="h2Title">
          Regalo <span className="boldText">Incluído</span>
        </h2>
        <p className="pText">
          Te adelantamos que este entrenamiento tiene una{' '}
          <span className="outstandingText">SORPRESA INCLUIDA</span> y que
          podrás disfrutarla si te inscribes y decides dar soltura a tu ritmo.
          <br /> <br />
          Aprende a mover tu cadera mientras liberas endorfinas y te conviertes
          en la sensación de la pista.
        </p>
      </Col>
      <Col
        xs={12}
        lg={6}
        xl={6}
        className="giftContent"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Parallax
          className="custom-class"
          y={[10, -10]}
          tagOuter="figure"
          disabled={mobile}
        >
          <div style={{ display: 'grid' }}>
            <img
              alt=""
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-offset="-400"
              className="giftImg"
              src={require('../../../../assets/img/photos/4---Twerkealo-min-min.png')}
              style={{ marginTop: '50px' }}
            ></img>
            <Button
              bsPrefix
              href="#vip"
              className="buttonText buttonImage giftButton"
            >
              Quiero empezar a Twerkear
            </Button>
          </div>
        </Parallax>
      </Col>
    </Row>
  );
};

export default GiftSection;
